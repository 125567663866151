var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Forms" } }),
          _c(
            "PageLayout",
            [
              !_vm.showEditDialog
                ? _c("v-data-table", {
                    staticClass: "py-0 my-0 cursor-pointer",
                    staticStyle: { "overflow-x": "hidden !important" },
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.flattenedFormsArray,
                      "item-key": "form_definition_id",
                      "disable-pagination": "",
                      "show-group-by": "",
                      "group-by": "Layer",
                      "hide-default-footer": "",
                      "custom-filter": _vm.filter,
                      search: _vm.search,
                    },
                    on: { "click:row": _vm.openEditFormDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center gap px-2 py-3",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Search",
                                      name: "search",
                                      "append-icon": "mdi-magnify",
                                      "hide-details": "auto",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            id: "add-form-button",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showNewFormDialog = true
                                              _vm.selectedFormDefinitionId =
                                                undefined
                                            },
                                          },
                                        },
                                        [_vm._v(" + Form ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "section",
                                {
                                  staticClass:
                                    "px-3 pt-0 pb-2 my-0 d-flex justify-start",
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "d-flex",
                                      on: {
                                        click: function ($event) {
                                          _vm.showActiveForms =
                                            !_vm.showActiveForms
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.showActiveForms
                                                  ? "Active"
                                                  : "Archived"
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiSyncCircle) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v("mdi-dots-vertical")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  item.is_active
                                    ? _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditFormDialog(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiPencil) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Edit Form "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateIsActive(
                                                    item.form_definition_id,
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiArchiveArrowDown
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Archive Form "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.showDeleteDialog = true
                                                  _vm.itemToDelete = item
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiDelete) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Delete Form "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateIsActive(
                                                    item.form_definition_id,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiArchiveArrowUp
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Make Active "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                fullscreen: _vm.$vuetify.breakpoint.xsOnly,
              },
              model: {
                value: _vm.showDeleteDialog,
                callback: function ($$v) {
                  _vm.showDeleteDialog = $$v
                },
                expression: "showDeleteDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pt-5" },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " Are you sure you want to delete this form? This cannot be undone. "
                    ),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteDialog = false
                              _vm.itemToDelete = {}
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(_vm.itemToDelete)
                            },
                          },
                        },
                        [_vm._v(" Delete Form ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.showNewFormDialog,
                callback: function ($$v) {
                  _vm.showNewFormDialog = $$v
                },
                expression: "showNewFormDialog",
              },
            },
            [
              _c("NewFormForm", {
                attrs: { mapServicesResults: _vm.mapServicesResults },
                on: {
                  "new-form-close": function ($event) {
                    _vm.showNewFormDialog = false
                  },
                  "create-new-form": function ($event) {
                    _vm.showNewFormDialog = false
                    _vm.showEditDialog = true
                    _vm.formIsNewForm = true
                    _vm.setMenuState(false)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "retain-focus": false,
                persistent: "",
                "content-class": "form-builder-edit-form",
              },
              model: {
                value: _vm.showEditDialog,
                callback: function ($$v) {
                  _vm.showEditDialog = $$v
                },
                expression: "showEditDialog",
              },
            },
            [
              _vm.showEditDialog
                ? _c("FormBuilderEditForm", {
                    attrs: {
                      selectedFormDefinitionId: _vm.selectedFormDefinitionId,
                      formIsNewForm: _vm.formIsNewForm,
                    },
                    on: {
                      "form-builder-edit-form-submitted": function ($event) {
                        _vm.selectedFormDefinitionId = $event
                      },
                      "form-builder-edit-form-close": function ($event) {
                        _vm.resetURL()
                        _vm.formIsNewForm = false
                        _vm.showEditDialog = false
                        _vm.downloadMapServices()
                        _vm.openMenu()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.showDeleteErrorDialog,
                callback: function ($$v) {
                  _vm.showDeleteErrorDialog = $$v
                },
                expression: "showDeleteErrorDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pt-5" },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " This form cannot be deleted because it is being referenced in other places in the app. We recommend you archive the form instead. "
                    ),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteErrorDialog = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }