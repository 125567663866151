var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { height: "100vh", elevation: "0" } },
    [
      _c(
        "v-card-text",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column justify-center",
              attrs: { height: "100vh", elevation: "0" },
            },
            [
              _c(
                "v-card-text",
                [
                  _c("p", { staticClass: "ml-n3" }, [_vm._v("Signing in")]),
                  _c("v-progress-circular", { attrs: { indeterminate: "" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }